import { useState, useEffect, useRef } from 'react'
import { useScrollTracker } from 'react-scroll-tracker'
import { getCLS, getFID, getLCP, getINP, getFCP, getTTFB } from 'web-vitals'
import { createApiReporter } from 'web-vitals-reporter'
import * as Sentry from '@sentry/gatsby'
import Player from '@vimeo/player'
import { getParams, getCookies, fetchWitRetry } from './utils'

const T365Days = Math.round(365 * 24 * 60 * 60 * 1e3)
const T2Hours = Math.round(2 * 60 * 60 * 1e3)

const LOCAL_STORAGE_FALLBACK = {}

const getRootDomain = (hostname) => {
  const urlParts = hostname.split('.')
  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.')
}

export const configureClientsWithPersonalData = (userId, userEmail) => {
  if (window.FS) {
    window.FS.identify(userId, {
      email: userEmail
    })
  }
  const sentryConfig = {
    id: userId
  }
  if (userEmail) {
    sentryConfig.email = userEmail
  }
  Sentry.setUser(userEmail)
}

function setItem(key, value, ttl) {
  const itemWrapper = JSON.stringify({
    value,
    expires: ttl ? new Date().getTime() + ttl : undefined
  })

  try {
    window.localStorage.setItem('ka_' + key, itemWrapper)
  } catch {
    void 0
  }
  LOCAL_STORAGE_FALLBACK['ka_' + key] = itemWrapper
  return value
}

function getItem(key) {
  let itemWrapper
  try {
    itemWrapper = window.localStorage.getItem('ka_' + key)
  } catch {
    void 0
  }
  itemWrapper = itemWrapper || LOCAL_STORAGE_FALLBACK['ka_' + key]

  if (!itemWrapper) {
    return null
  }
  let item = itemWrapper
  try {
    item = JSON.parse(itemWrapper)
    if (item.expires && new Date().getTime() > item.expires) {
      try {
        window.localStorage.removeItem('ka_' + key)
        delete LOCAL_STORAGE_FALLBACK['ka_' + key]
      } catch {
        void 0
      }
      return null
    }
    item = item.value
  } catch (e) {
    void 0
  }
  return item
}

const createCurrentTimestampAsId = () => parseInt(new Date().getTime() / 1000)

const configureSessionData = () => {
  const params = getParams(window.location.search)
  const cookies = getCookies(window.document.cookie)
  const rootDomain = getRootDomain(window.location.hostname)
  const session = getSessionData()

  const now = new Date().getTime()

  const fbp =
    session.fbp ||
    cookies._fbp ||
    `fb.1.${now}.${Math.round(2147483647 * Math.random())}`
  setItem('fbp', fbp, T365Days)

  const fbc =
    (params.fbclid && `fb.1.${now}.${params.fbclid}`) ||
    session.fbc ||
    cookies._fbc

  if (fbc) {
    setItem('fbc', fbc, T365Days)
  }

  const ref = params.ref || session.ref || cookies.ka_ref
  if (ref) {
    setItem('ref', ref, T365Days)
  }

  const utm_source =
    params.utm_source || session.utm_source || cookies.ka_utm_source
  if (utm_source) {
    setItem(
      'utm_source',
      decodeURIComponent(decodeURIComponent(utm_source)),
      T365Days
    )
  }

  const currentTimestamp = createCurrentTimestampAsId()

  const uid =
    session.uid ||
    cookies.ka_uid ||
    `${generateRandom10DigitsNumber()}.${currentTimestamp}`
  setItem('uid', uid, T365Days)

  const sid = session.sid || cookies.ka_sid || `${currentTimestamp}`
  setItem('sid', sid, T2Hours)

  if (cookies.ka_uid) {
    document.cookie = `_fbp=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.${rootDomain}; path=/`
    document.cookie = `_fbc=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.${rootDomain}; path=/`
    document.cookie = `ka_ref=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.${rootDomain}; path=/`
    document.cookie = `ka_utm_source=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.${rootDomain}; path=/`
    document.cookie = `ka_uid=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.${rootDomain}; path=/`
    document.cookie = `ka_sid=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.${rootDomain}; path=/`
  }

  return uid
}

export const getSessionData = () => ({
  uid:
    getItem('uid') ||
    setItem(
      'uid',
      `${generateRandom10DigitsNumber()}.${createCurrentTimestampAsId()}`,
      T365Days
    ),
  sid:
    getItem('sid') ||
    setItem('sid', '' + createCurrentTimestampAsId(), T2Hours),
  ref: getItem('ref') || undefined,
  utm_source: getItem('utm_source') || undefined,
  fbp: getItem('fbp') || undefined,
  fbc: getItem('fbc') || undefined
})

export const persistPersonalData = (user) => {
  if (user.email) {
    setItem('user_email', user.email.trim().toLowerCase())
  }
  if (user.phone) {
    setItem('user_phone', phoneToE164(user.phone.trim()))
  }
  if (user.name) {
    setItem('user_name', user.name)
  }
  if (user.id) {
    setItem('user_id', user.id + '')
  }
  if (user.fluent_crm_id) {
    setItem('fluent_crm_id', user.fluent_crm_id + '')
  }
}

const getPersistedPersonalData = () => ({
  email: window.localStorage.getItem('ka_user_email') || undefined,
  phone: window.localStorage.getItem('ka_user_phone') || undefined,
  name: window.localStorage.getItem('ka_user_name') || undefined,
  id: window.localStorage.getItem('ka_user_id') || undefined,
  fluent_crm_id:
    window.localStorage.getItem('ka_user_fluent_crm_id') || undefined
})

export const removePersonalData = () => {
  window.localStorage.removeItem('ka_user_email')
  window.localStorage.removeItem('ka_user_phone')
  window.localStorage.removeItem('ka_user_name')
  window.localStorage.removeItem('ka_user_id')
  window.localStorage.removeItem('ka_user_fluent_crm_id')
}

const generateRandom10DigitsNumber = () =>
  Math.floor(Math.random() * 9000000000) + 1000000000

function round(val, precision = 0) {
  return +(Math.round(`${val}e+${precision}`) + `e-${precision}`)
}

export const init = () => {
  if (window.PerformanceObserver) {
    configureWebVitals()
  }
  const uid = configureSessionData()
  const user = getPersistedPersonalData()
  configureClientsWithPersonalData(user.fluent_crm_id || uid, user.email)
}

export const configureWebVitals = () => {
  const sendToAnalytics = createApiReporter('', {
    mapMetric: (metric) => {
      const isWebVital =
        ['FCP', 'TTFB', 'LCP', 'CLS', 'FID'].indexOf(metric.name) !== -1
      return {
        [metric.name]: isWebVital
          ? round(metric.value, metric.name === 'CLS' ? 4 : 0)
          : metric.value,
        [`${metric.name}_R`]: metric.rating,
        [`${metric.name}_NT`]: metric.navigationType
      }
    },
    onSend: (_, params) => {
      delete params.id
      fireEvent('WebVitals', null, params)
    }
  })
  getCLS(sendToAnalytics)
  getFID(sendToAnalytics)
  getLCP(sendToAnalytics)
  getINP(sendToAnalytics)
  getFCP(sendToAnalytics)
  getTTFB(sendToAnalytics)
}

// https://developers.facebook.com/docs/marketing-api/conversions-api/offline-events
// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters
// https://developers.facebook.com/docs/marketing-api/conversions-api/using-the-api/
// https://developers.facebook.com/docs/marketing-api/conversions-api/guides/gtm-server-side/
// https://developers.google.com/tag-platform/tag-manager/server-side/common-event-data
// https://github.com/facebookincubator/ConversionsAPI-Tag-for-GoogleTagManager
// https://developers.facebook.com/docs/meta-pixel/reference/
// https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking
// https://developers.facebook.com/docs/meta-pixel/advanced
// https://developers.facebook.com/ads/blog/post/v2/2017/11/28/event-tracking-with-multiple-pixels-tracksingle/
// https://www.cloudflare.com/application-services/products/zaraz/
export const fireEvent = async (event, product, extra) => {
  const session = getSessionData()
  const user = getPersistedPersonalData()

  const { downlink, effectiveType, rtt, saveData } =
    (window.navigator && window.navigator.connection) || {}

  const url = `/core/wp/?action=api&method=analytics`
  const params = {
    event_name: event,
    event_source_url: decodeURI(window.location.href),
    uid: session.uid,
    sid: session.sid,
    referrer: document.referrer ? decodeURI(document.referrer) : undefined,
    utm_source: session.utm_source,
    ref: session.ref,
    fbp: session.fbp,
    fbc: session.fbc,
    user_data: {
      aid: user.user_id,
      crmid: user.fluent_crm_id,
      email: user.email,
      phone: user.phone,
      name: user.name
    },
    device_data: {
      connection: { downlink, effectiveType, rtt, saveData },
      memory: window.navigator && window.navigator.deviceMemory,
      cpus: window.navigator && window.navigator.hardwareConcurrency,
      language: window.navigator && window.navigator.language,
      languages: window.navigator && window.navigator.languages
    },
    product: product
      ? {
          category: product.category,
          name: product.name,
          type: 'product',
          price: product.price,
          full_price: product.fullPrice,
          user_coupons: product.userCoupons,
          system_coupons: product.systemCoupons,
          currency: 'ILS',
          order_id: product.transactionId
        }
      : {},
    extra
  }

  let isSuccess

  try {
    const blob = new window.Blob([JSON.stringify(params)], {
      type: 'text/plain'
    })
    isSuccess = window.navigator.sendBeacon(url, blob)
  } catch (e) {
    Sentry.captureException(e)
    try {
      const response = await fetchWitRetry(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
        keepalive: true
      })
      if (response.status !== 200) {
        throw new Error(
          `${response.status} ${response.statusText} ${await response.text()}`
        )
      }
      isSuccess = true
    } catch (e) {
      Sentry.captureException(e)
      isSuccess = false
    }
  }
  return isSuccess
}

export const phoneToE164 = (phone) =>
  phone
    ? (phone.startsWith('0')
        ? `972${phone.slice(1)}`
        : phone.startsWith('+')
        ? phone.slice(1)
        : phone
      )
        .replace('-', '')
        .replace(' ', '')
        .replace('(', '')
        .replace(')', '')
    : undefined

export const useTimeOnPage = (product) => {
  const [timeOnPageEventIntervals, setTimeOnPageEventIntervals] = useState([
    0, 15, 30, 60, 90, 120, 180, 240, 300
  ])
  const [currentTimer, setCurrentTimer] = useState(null)
  const [cycleDisturbedTime, setCycleDisturbedTime] = useState(null)

  useEffect(() => {
    if (timeOnPageEventIntervals.length === 1) {
      return
    }
    function handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        setTimeOnPageEventIntervals([
          timeOnPageEventIntervals[0] + cycleDisturbedTime,
          ...timeOnPageEventIntervals.slice(1)
        ])
      } else {
        setCycleDisturbedTime(
          (new Date().getTime() - cycleDisturbedTime) / 1000
        )
        clearTimeout(currentTimer)
        setCurrentTimer(null)
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [timeOnPageEventIntervals, currentTimer, cycleDisturbedTime])
  useEffect(() => {
    if (timeOnPageEventIntervals.length === 1) {
      return
    }
    const [prev, current, ...restTimeOnPageEventIntervals] =
      timeOnPageEventIntervals
    const timer = setTimeout(() => {
      if (document.visibilityState === 'visible') {
        fireEvent('TimeOnPage', product, { seconds: current })
        setTimeOnPageEventIntervals([current, ...restTimeOnPageEventIntervals])
      }
    }, (current - prev) * 1000)
    setCycleDisturbedTime(new Date().getTime())
    setCurrentTimer(timer)
    return () => {
      clearTimeout(timer)
      setCurrentTimer(null)
    }
  }, [timeOnPageEventIntervals])
}

export const useScroll = (product) => {
  return useScrollTracker(
    [5, 15, 25, 35, 45, 55, 65, 75, 85, 95],
    ({ scrollY }) => {
      fireEvent('ScrollDepth', product, { percent: scrollY })
    }
  )
}

export const useVideo = (product) => {
  const iframeRef = useRef(null)
  useEffect(() => {
    if (iframeRef && iframeRef.current) {
      const videoWatchedPercentEventIntervals = [5, 25, 50, 75, 95, 100]
      const player = new Player(iframeRef.current)
      player.on('timeupdate', async (data) => {
        if (data.percent * 100 >= videoWatchedPercentEventIntervals[0]) {
          const rate = await player.getPlaybackRate()
          const videoId = await player.getVideoId()
          const videoTitle = await player.getVideoTitle()
          fireEvent('VideoWatched', product, {
            percent: videoWatchedPercentEventIntervals[0],
            time: data.seconds,
            total: data.duration,
            rate,
            videoId,
            videoTitle
          })
          videoWatchedPercentEventIntervals.shift()
        }
      })
      return () => {
        player.unload()
      }
    }
  }, [])
  return iframeRef
}
